import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import ShortRedirect from './pages/ShortRedirect';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        <Route path="/s/:url" element={<ShortRedirect />} />
          <Route path="/" element={<Home />} />
          
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
