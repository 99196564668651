import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

export const NeumorphButton = styled(LoadingButton)({
    "&": {
        color: "#343A40",
        border: "none",
        borderRadius: '29px',
        background: '#e6e6e6',
        boxShadow:  '10px 10px 20px #969696, -10px -10px 20px #ffffff',
    },
    "&:hover": {
        border: "none",
        borderRadius: '29px',
        background: '#e6e6e6',
        boxShadow:  `-2px -2px 6px rgba(255, 255, 255, .6), 
                    -2px -2px 4px rgba(255, 255, 255, .4),
                    2px 2px 2px rgba(255, 255, 255, .05),
                    2px 2px 4px rgba(0, 0, 0, .1);`
    },
    "&:active": {
        boxShadow:  `inset -2px -2px 6px rgba(255, 255, 255, .6), 
                    inset -2px -2px 4px rgba(255, 255, 255, .4),
                    inset 2px 2px 2px rgba(255, 255, 255, .05),
                    inset 2px 2px 4px rgba(0, 0, 0, .1);`
    }
});

export const UrlTextField = styled(TextField)({
    '& input': {
        color: '#343A40',
        fontSize: "1.5rem"
    },
    '& label, label.Mui-focused': {
        color: '#343A40',
        fontSize: "1.5rem"
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#343A40',
            color: '#343A40',
            fontSize: "1.5rem"
        },
        '& label': {
            color: '#343A40',
            fontSize: "1.5rem"
        },
        '&.Mui-focused fieldset': {
            borderColor: '#343A40',
            fontSize: "1rem"
        },
        '&:hover fieldset': {
            borderColor: '#343A40',
            fontSize: "1.5rem"
        }
      }
});