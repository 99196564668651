import { useState } from 'react'

import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import "./Home.css"

import { NeumorphButton, UrlTextField } from '../components/StyledComponents';

const validateURL = (url) => {
    const regex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/;
    return regex.test(url);
}

const validateShortURL = (url) => {
    const regex = /^((http(s)?:\/\/)?(www\.)?s.alexpts.dev\/s\/[a-zA-Z0-9]{6})|([a-zA-Z0-9]{6})$/;
    return regex.test(url);
}

export default function Home() {

    const [recievedShortURL, setRecievedShortURL] = useState(false);
    const [shortURL, setShortURL] = useState('');
    const [url, setURL] = useState("");

    const [recievedAnalytics, setRecievedAnalytics] = useState(false);
    const [showAnalytics, setShowAnalytics] = useState(false);
    const [analyticsShortURL, setAnalyticsShortURL] = useState("");
    const [analytics, setAnalytics] = useState({});

    const [helperText, setHelperText] = useState("");
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tooltipText, setTooltipText] = useState("Click to copy");

    const handleShortUrl = async (e) => {
        e.preventDefault();
        if(!url) {
            setError(true);
            setHelperText("URL is required");
            return;
        }

        setError(false);
        setHelperText("");
        setLoading(true);

        if(url.length > 0) {
            if(!validateURL(url)) {
                setError(true);
                setHelperText("Invalid URL");
                setLoading(false);
                return;
            }

            try {
                const response = await fetch(
                    `https://url-shortener.api.alexpts.dev/api/urls?url=${url}`,
                    {
                        method: "POST",
                        mode: "cors",
                    });

                const data = await response.json();
                if(response.status !== 200){
                    setError(true);
                    setHelperText(data.message);
                    setLoading(false);
                    return;
                }

                setRecievedShortURL(true);

                setShortURL(`s.alexpts.dev/s/${data.data.shortUrl}`);
                setLoading(false);
            } catch (err) {
                console.error(err);
                setLoading(false);
            }
        }
    }

    const handleShowAnalytics = async () => {
        if(!analyticsShortURL) {
            setError(true);
            setHelperText("Short URL is required");
            return;
        }
        setError(false);
        setHelperText("");
        setLoading(true);
        if(analyticsShortURL.length > 0) {
            if(!validateShortURL(analyticsShortURL)) {
                setError(true);
                setHelperText("Invalid URL");
                setLoading(false);
                return;
            }
            let urlToSend;
            if(analyticsShortURL.includes("s.alexpts.dev/s/")) {
                urlToSend = analyticsShortURL.split("s.alexpts.dev/s/")[1];
            } else {
                urlToSend = analyticsShortURL;
            }
            try {
                const response = await fetch(
                    `https://url-shortener.api.alexpts.dev/api/stats?url=${urlToSend}`,
                    {
                        method: "GET",
                        mode: "cors",
                    });
    
                const data = await response.json();
                if(response.status !== 200){
                    setError(true);
                    setHelperText(data.message);
                    setLoading(false);
                    return;
                }
                setAnalytics(data.data);
                setRecievedAnalytics(true);
                setLoading(false);
            } catch (err) {
                console.error(err);
                setLoading(false);
            }
        }
        
    }



  return (
    <div>
        <h1 className='title' onClick={() => {
            setRecievedShortURL(false)
            setShortURL("")
            setURL("")
            setRecievedAnalytics(false)
            setShowAnalytics(false)
            setAnalyticsShortURL("")
            setAnalytics(false)
            setHelperText("")
            setError("")
            setLoading(false)
            setTooltipText("Click to copy")
        }}>URL Shortener</h1>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: "60vh" }}
            >
                <Grid item xs={3}>
                    <div className="box" style={{ minWidth: 360, minHeight: 260, maxWidth: 600, maxHeight: 600, padding: 10 }} >
                    {!recievedShortURL && !recievedAnalytics && 
                        <div style={{ padding: 20 }}>
                            {!showAnalytics && <>
                                <h2 style={{ fontSize: "2rem", color: "var(--dark-gray)" }}>URL you want to shorten:</h2>
                                <form onSubmit={handleShortUrl}>
                                    <label>
                                        <UrlTextField
                                            onChange={(e) => setURL(e.target.value)}
                                            value={url}
                                            placeholder="example.com"
                                            error={error}
                                            helperText={helperText}
                                        />
                                    </label>
                                </form>
                                <NeumorphButton disableRipple type="submit" onClick={handleShortUrl} sx={{ mt: "20px" }} variant="outlined" loading={loading}>Generate short URL</NeumorphButton>
                                <br />
                                <NeumorphButton disableRipple onClick={() => {setError(false); setHelperText(""); setShowAnalytics(true)}} sx={{ mt: "20px" }}>Analytics for short URL</NeumorphButton>
                            </>}
                            {showAnalytics && <>
                                <h2 style={{ fontSize: "2rem", color: "var(--dark-gray)" }}>Short URL:</h2>
                                <form onSubmit={handleShortUrl}>
                                    <label>
                                        <UrlTextField
                                            onChange={(e) => setAnalyticsShortURL(e.target.value)}
                                            value={analyticsShortURL}
                                            placeholder="s.alexpts.dev/s/l33tcd or l33tcd"
                                            error={error}
                                            helperText={helperText}
                                        />
                                    </label>
                                </form>
                                <NeumorphButton disableRipple onClick={handleShowAnalytics} sx={{ mt: "20px" }}>Get analytics</NeumorphButton>
                                <br />
                                <NeumorphButton disableRipple onClick={() => {setAnalyticsShortURL(""); setShowAnalytics(false); setError(false); setHelperText(false)}} sx={{ mt: "20px" }}>Back</NeumorphButton>
                            </>}
                            <br />
                        </div>
                    }
                    {recievedShortURL &&
                        <>
                            <h2 style={{ fontSize: "2rem" }}>Your shortened URL:</h2>
                            <span style={{ fontSize: "1.5rem" }}>
                                {shortURL}
                            </span>

                            <Tooltip title={tooltipText} sx={{ ml: "10px" }}>
                                <IconButton aria-label="copy short url" onClick={async () => {
                                    navigator.clipboard.writeText(shortURL);
                                    setTooltipText("Copied!");
                                    await new Promise(resolve => setTimeout(resolve, 1000));
                                    setTooltipText("Click to copy");
                                }}>
                                    <ContentCopyIcon style={{ color: "black" }}/>
                                </IconButton>
                            </Tooltip>

                            <br />

                            <NeumorphButton sx={{ mt: "40px" }} onClick={() => {
                                setRecievedShortURL(false);
                                setShowAnalytics(false);
                                setShortURL('');
                                setURL('');
                            }}>
                                Create new URL
                            </NeumorphButton>
                        </>
                    }
                    {recievedAnalytics &&
                        <>
                            <div>
                                <h2 style={{ fontSize: "2rem" }}>Analytics for {analytics?.shortUrl}:</h2>
                                <p style={{ fontSize: "1.5rem" }}>Total visits: {analytics?.visits}</p>
                                <span style={{ fontSize: "1.5rem" }}>Redirects to:</span>
                                <br />
                                <span style={{ fontSize: "1.5rem", maxWidth: 350 }}>{analytics?.originalUrl}</span>
                                
                            </div>
                            <NeumorphButton sx={{mt: "40px"}} onClick={() => {setRecievedAnalytics(false); setAnalyticsShortURL("")}}>
                                Analytics for another URL
                            </NeumorphButton>
                            <br />
                            <NeumorphButton sx={{mt: "20px"}} onClick={() => {setRecievedAnalytics(false); setShowAnalytics(false); setAnalyticsShortURL(""); setError(false); setHelperText(false)}}>
                                Back to start
                            </NeumorphButton>
                        </>
                    }
                    </div>
                </Grid>
            </Grid>
        <a style={{ color: "var(--dark-gray)" }} href='mailto:support@alexpts.dev?body=app%3A%20URL-Shortener%0A---%20please%20leave%20the%20content%20above%20this%20line%20---'>support@alexpts.dev</a>
    </div>
  )
}
