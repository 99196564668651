import { useParams } from "react-router-dom"
import { useEffect, useState } from 'react'

import { Link } from "react-router-dom";

export default function ShortRedirect() {

    const { url } = useParams();
    const [originalUrl, setOriginalURL] = useState('');
    const [gotUrl, setGotUrl] = useState(false);

    useEffect(() => {
        async function fetchData() {
            let responseData;
            if(url.length > 0) {
                try {
                    await fetch(
                        `https://url-shortener.api.alexpts.dev/api/urls?url=${url}`,
                        {
                            method: "GET",
                            mode: "cors",
                        })
                        .then(response => response.json())
                        .then(json => {responseData = json});


                    if(responseData.status !== 200){
                        setOriginalURL(null);
                        setGotUrl(false);
                        return
                    }

                    if(responseData.status === 200){
                        setOriginalURL(responseData.data.originalUrl);
                        setGotUrl(true);
                    }
                    
                    
                    
                } catch (err) {
                    console.error(err);
                }
                if(originalUrl !== null){    
                    await new Promise(resolve => setTimeout(resolve, 3000));
                    // Check if url contains http (or https) in the beginning and set it if needed
                    // because window.location won't redirect without it
                    try {
                        if(originalUrl.slice(0, 4) === "http") {
                            window.location = originalUrl;
                        } else {
                            window.location = "https://" + originalUrl;
                        }   
                    } catch (error) {}
                }
            }
        }
        fetchData()
    }, [originalUrl, url])
    
  return (
    <div style={{ color: "white"}}>
        {gotUrl &&
            <>
                <h1 style={{ color: "white" }}>You are getting redirected in a few seconds </h1><img src="/assets/loader.svg" alt="loading spinner" style={{ width: "50px", height: "50px" }} />
                <p style={{ fontSize: "1.3em" }}>Destination: {originalUrl}</p>
                <br />
                <br />
                <br />
                <Link style={{ color: "#fff" }} to={{ pathname: originalUrl }} target="_blank">If you don't get redirected, click here</Link>
            </>
        }
        {!gotUrl && originalUrl === null &&
            <>
                <h1>Nothing found</h1>
            </>
        }
    </div>
  )
}
